import React from "react"

import Layout from "../components/layout/Layout"
import SEO from '../components/layout/Seo';
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer';
import Container from "../components/layout/Container";
import Playground from '../components/layout/Playground';
import HomeParallax from '../components/homePage/HomeParallax';
import { Animated } from 'react-animated-css';
import Map from '../components/layout/Map';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import Preloader from '../components/layout/Preloader';

export default class NotFoundContainer extends React.Component {
	state = {
		loaded: false
	}
	
	componentDidMount = () => {
		setInterval(() => {
			this.setState({ loaded: true })
		}, 250)
	}

	render() {
		const { loaded } = this.state;
		const { location } = this.props;

		return (
			<Layout>
				{Boolean(!loaded) && <Preloader /> }
				<SEO pageTitle="404" />
				<Header location={location} />
				<Playground>
					<HomeParallax 
						title="Not found"
						animationIn="fadeIn"
						imageNumber={2}
					/>
					<Breadcrumbs
						way={[
							{
								title: '404',
								url: '/o-firmie',
								active: true
							}
						]}
					/>
					<Animated>
						<Container>
							<p>Page you are looking for is not found :(</p>
						</Container>
						<Map />	
					</Animated>
				</Playground>
				<Footer />
			</Layout>
		)
	}
}


